.home-filters-container{
    display: grid;
    place-items: center;
    grid-template-columns: repeat(2, 1fr);
    grid-template-rows: repeat(2, 1fr);
    margin: 15px 0;
    max-width: 550px;
}

.home-filter{
    margin: 10px;
    border-radius: 6px;
}

.item1{
    grid-column: 1 / span 2;
    width: 100%;
}

.filterImg{
    max-width: 100%;
    max-height: 100%;
    border-radius: 20px;
}
.cart-container{
    display: flex;
    flex-direction: column;
    width: 800px;
    margin-top: 2em;
}

.cart-item{
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin: 1em 0 ;
    box-shadow: 0px 0px 3px rgb(202, 201, 201);
    padding: 3px 10px;
}

.item-img{
    width: 6em;
    margin-right: 7px ;
}

.item-title{
    font-size: 0.8rem;
    width: 80%;
    justify-self: start;
}

.item-price{
    font-size: 0.8rem;
    font-weight: bold;
}

.remove-button{
    font-size: 0.7em;
    background-color: transparent;
    border: none;
}
.remove-button-picture{
    width: 2.3em;
}

.remove-button-picture:active::after{
    padding: 0;
    margin: 0;
    opacity: 1;
    transition: 0s
}

a{
    color: black;
    font-weight: bold;
}

@media screen and (max-width: 500px) {

.cart-container{
    width: 90%;
}

    .item-img{
        width: 3em;
        margin-right: 7px ;
    }
}
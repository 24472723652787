.navbar{
    display: flex;
    justify-content: space-between;
    align-items: center;
    background-color: rgb(171, 121, 55);
    width: 100%;
    height: 100px;
    position: -webkit-sticky;
    position: sticky;
    top: 0px;
}

.logo{
    margin-left: 50px;
}

.cart-favourites{
    display: flex;
    justify-content: space-between;
    align-items: flex-end;
    margin-right: 4%;
    width: 20%;
}

.cart, .favourites{
    width: 1.9rem;
    height: 1.9rem;
    cursor: pointer;
}

.cart-icon-container{
    display: flex;
    flex-direction: column-reverse;
    justify-content: center;
    align-items: center;
}

.itemCount{
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 0.8rem;
    width: 1.3em;
    height: 1.3em;
    border-radius: 50%;
    background-color: brown;
    transform: translate(1.5px, 10px);
}

@media only screen and (max-width: 450px) {
    .navbar{
        height: 80px;
    }
    
    .logo{
        margin-left: 20px;
        width: 55%;
    }

    .cart-favourites{
        margin-right: 4%;
    }
    
    .itemCount{
        font-size: 0.5rem;
    }
    
}

.product-preview{
    display: flex;
    justify-content: center;
    width: 70%;
}

.details{
    max-width: 50%;
    font-family: Poppins, sans-serif;
    color: #515151;
    margin-right: 2em;
}
.details-img{
    width: 15em;
    margin: 30px 50px
}

.product-details{
    text-align: left;
    align-self: center;
}

.details-title{    
    font-family: Impact, Haettenschweiler, 'Arial Narrow Bold', sans-serif;
    font-size: large;
}

.details-price{
    color: rgb(83, 82, 82);
}

.count-of-reviews{
    color: rgba(161, 155, 155, 0.53);
    font-size: small;
    text-transform: uppercase;
}

.add-to-cart{
    width: 100%;
    height: 25px;
    margin-bottom: 16px;
    background-color: rgb(54, 50, 50);
    color: aliceblue;
    text-transform: uppercase;
    border: none;
    font-size: 0.7rem;
}

.add-to-cart:active{
    transform: translateY(1px);
}

.add-to-wishlist{
    width: 100%;
    height: 25px;
    background-color: transparent;
    border: 1px solid rgb(213, 204, 204);
    text-transform: uppercase;
    font-size: 0.7rem;
    color: black;
}

.add-to-wishlist:active{
    transform: translateY(1px);

}

@media only  screen  and (max-width: 550px) {
    .product-preview{
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        align-items: center;
        width: 90%;
    }
    
    .details{
        display: flex;
        flex-direction: column;
        width: 90%;
        align-items: center;
        font-family: Poppins, sans-serif;
        color: #515151;
        text-align: start;
        margin-right: 0em;

    }

    details{
        align-self: flex-start;
    }

    .details-img{
        max-width: 100px;
        min-width: 100px;
        margin: 30px 50px
    }
}
.cards-container{
    display: flex;
    flex-direction: column;
    flex-wrap: wrap;
    justify-content: center;
    align-items: center;
    max-width: 90%;
}

.filters{
    display: flex;
    /* grid-template-columns: repeat(02, 1fr); */
    /* grid-template-rows: repeat(2, 1fr); */
    max-width: 90%;
    justify-content: center;
    align-items: center;
    margin-top: 20px;
}

.filter{
    margin: 10px;
    padding: 3px 10px;
    font-size: 0.8em;
    border-radius: 20px;
    background-color: rgb(236, 235, 233);
    font-family: 'Poppins', sans-serif;
    cursor: pointer;
}

.products{
    display: grid;
    grid-template-columns: 1fr 1fr 1fr 1fr;
}

.card{
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
    margin: 20px;
    padding: 6px;
    border-radius: 10px;
    box-shadow: 0px 0px 5px rgb(231, 231, 231) ;
    width: 13em;
}

.product-card{
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    align-items: center;
    font-family: 'Poppins', sans-serif;
}

.card-image{
    max-width: 11em;
    max-height: 13em;
    margin-bottom: 10px;
}

.card-details{
    font-size: 0.8em;
    margin: 0px;
}

.title{
    font-size: 0.7rem;
    margin-top: 5px;
}

.price-and-favButton{
    display: flex;
    align-items: center
}

.price{
    font-weight: 600;
    color: rgb(135, 135, 135);
    width: 100%;
}

.fav-icon{
    width: 30px;
    cursor: pointer;
}

@media only screen and (max-width: 1044px) {
    .products{
        display: grid;
        grid-template-columns: 1fr 1fr 1fr;
    }
}

@media only screen and (max-width: 550px) {

    .cards-container{
        display: flex;
        flex-direction: column;
        flex-wrap: wrap;
        justify-content: center;
        align-items: center;
    }
    
    .filters{
        display: grid;
        grid-template-columns: repeat(02, 1fr);
        grid-template-rows: repeat(2, 1fr);
        margin: 15px 0;
        max-width: 90%;
    }
    
    .filter{
        margin: 5px;
        border-radius: 6px;
        padding: 5px;
        width: 90%;
    }

    .item1{
        grid-column: 1 / span 2;
        width: 100%;
    }
    
    .filterImg{
        max-width: 100%;
        max-height: 100%;
        border-radius: 20px;
    }
    
    .products{
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
    }

    .card{
        display: flex;
        flex-direction: row;
        border-radius: 10px;
        box-shadow: 0px 0px 5px rgb(231, 231, 231) ;
        margin: 4px;
        padding: 5px;
        justify-content: space-between;
        align-items: center;
        width: 90%;
        height: auto;
    }
    
    .product-card{
        display: flex;
        justify-content: flex-start;
        flex-direction: row;
        width: 80%;
    }
    
    .card-image{
        max-width: 5em;
        max-height: 5em;
        margin: 5px 10px;
    }
    
    .card-details, .title{
        font-size: 0.5rem;
    }
    
    .price-cart-favourite{
        display: flex;
        flex-direction: column;
        width: 15%;
        justify-content: center;
        align-items: center;
    }
    
    .fav-icon{
        width: 3em;
        transform: translate(0px);
        align-self: flex-end;
    }

    .price{
    font-size: 0.8rem;
    margin: 5px;

    }
}